export default [
  {
    links: [
      {
        text: 'Home',
        to: '/'
      },
      {
        text: 'About',
        to: '/about'
      },
      {
        text: 'Contact',
        to: '/contact'
      }
    ],
    text: 'Company'
  },
  {
    links: [
      {
        text: 'Disability Specialist Services',
        to: '/services/positive-behaviour-support'
      },
      {
        text: 'Mental Health Services',
        to: '/services/mental-health-services'
      },
      { text: 'Supervision', to: '/services/supervision' },
      {
        text: 'Agency Specialist Services',
        to: '/services/training-agency-specialist-services'
      }
    ],
    text: 'Psychology Services'
  },
  {
    links: [
      {
        text: 'Contact Rob Today',
        to: '/contact'
      },

      // {
      //   text: 'FAQs',
      //   to: '/faqs'
      // },
      {
        text: '0419 236 597'
      }
    ],
    text: 'Support'
  }
]
