export default [
  {
    text: 'Home',
    to: '/'
  },
  {
    text: 'Psychology Services',
    width: '250px',
    dropdown: [
      // { text: 'Assessments', to: '/services/assessment' },
      {
        text: 'Disability Specialist Services',
        to: '/services/positive-behaviour-support'
      },
      {
        text: 'Mental Health Services',
        to: '/services/mental-health-services'
      },
      { text: 'Supervision', to: '/services/supervision' },
      {
        text: 'Agency Specialist Services',
        to: '/services/training-agency-specialist-services'
      }
    ]
  },
  {
    text: 'About',
    to: '/about'
  },
  // {
  //   text: 'FAQs',
  //   to: '/faqs'
  // },
  {
    text: 'Contact',
    to: '/contact'
  },
  {
    text: 'Contact',
    to: '/contact'
  }
]
