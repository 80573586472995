import { Link } from 'gatsby'
import * as React from 'react'

import Arrow from '../Arrow'
import {
  Dropdown,
  DropdownContainer,
  DropdownLink,
  MenuIcon,
  Nav,
  NavButton,
  NavItem,
  NavLink,
  NavList,
  NavListMain,
  NavLogo,
  CloseIcon,
  PhoneNumber
} from './styles'

import PhoneIcon from 'react-icons/lib/fa/phone'

import header from './navigation'

interface IProps {
  theme?: 'light' | 'dark'
}

interface IState {
  mobileNavVisible: boolean
  isScrolled: boolean
}

export default class extends React.Component<IProps, IState> {
  state = {
    isScrolled: false,
    mobileNavVisible: false
  }

  toggleNav = () =>
    this.setState({ mobileNavVisible: !this.state.mobileNavVisible })

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (window.pageYOffset > 10) {
      this.setState({ isScrolled: true })
    } else {
      this.setState({ isScrolled: false })
    }
  }

  render() {
    const { theme } = this.props
    return (
      <Nav
        className={this.state.isScrolled && 'is-fixed'}
        visible={this.state.mobileNavVisible}
      >
        <div>
          <Link to="/" style={{ float: 'left' }}>
            <NavLogo
              noText={
                typeof window !== 'undefined' && window.innerWidth < 500
                  ? true
                  : false
              }
              color={this.state.isScrolled ? 'primary' : theme || 'primary'}
            />
          </Link>
        </div>

        <NavListMain
          color={theme}
          className={this.state.mobileNavVisible && 'is-mobile-visible'}
        >
          <CloseIcon onClick={this.toggleNav}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
            >
              <path d="M39.4 39.4c-.8.8-2 .8-2.8 0L24 26.8 11.4 39.4c-.8.8-2.1.8-2.8 0-.8-.8-.8-2 0-2.8L21.2 24 8.6 11.4c-.8-.8-.8-2.1 0-2.8.8-.8 2-.8 2.8 0L24 21.2 36.6 8.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8L26.8 24l12.6 12.6c.8.8.8 2 0 2.8z" />
            </svg>
          </CloseIcon>
          {header.map(item => (
            <NavItem key={item.text} hasDropdown={item.dropdown}>
              <NavLink to={item.to || '#'}>
                {item.text}
                {item.dropdown && <Arrow />}
              </NavLink>
              {item.dropdown && (
                <Dropdown width={item.width} flex={item.hasSubSections}>
                  {item.dropdown.map(subItem => {
                    if (subItem.links) {
                      return (
                        <DropdownContainer key={subItem.text}>
                          <h5 style={{ color: '#fff' }}>{subItem.text}</h5>
                          {subItem.links.map(link => (
                            <DropdownLink key={link.text} to={link.to}>
                              {link.text}
                            </DropdownLink>
                          ))}
                        </DropdownContainer>
                      )
                    }
                    return (
                      <DropdownLink key={subItem.text} to={subItem.to}>
                        {subItem.text}
                      </DropdownLink>
                    )
                  })}
                </Dropdown>
              )}
            </NavItem>
          ))}
        </NavListMain>

        <div>
          <NavList color={theme}>
            <NavItem>
              <PhoneNumber
                href="tel:0419236597"
                onClick={() => {
                  if (typeof window !== 'undefined') {
                    window.ga('send', 'pageview', '/contact-enquiry/')
                  }
                }}
              >
                <PhoneIcon style={{ marginRight: 8 }} />
                0419&nbsp;236&nbsp;597
              </PhoneNumber>
            </NavItem>
            <NavItem>
              <Link to="/contact#form">
                <NavButton>Contact Rob Today</NavButton>
              </Link>
            </NavItem>
            <MenuIcon onClick={this.toggleNav}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <path d="M7 8h34c1.1 0 2 .9 2 2s-.9 2-2 2H7c-1.1 0-2-.9-2-2s.9-2 2-2zm0 14h34c1.1 0 2 .9 2 2s-.9 2-2 2H7c-1.1 0-2-.9-2-2s.9-2 2-2zm0 14h34c1.1 0 2 .9 2 2s-.9 2-2 2H7c-1.1 0-2-.9-2-2s.9-2 2-2z" />
              </svg>
            </MenuIcon>
          </NavList>
        </div>
      </Nav>
    )
  }
}
